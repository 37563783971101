import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import * as AceOceanValidationRegex from 'src/common/AceOceanValidationRegex'
import AceOcnVesselMasterKey from 'src/constants/locale/key/AceOcnVesselMaster'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumericSpecChar = AceOceanValidationRegex.REGEX_ALPHA_NUMERIC_SPEC_CHAR
    //'^[a-zA-Z0-9 ,+\\-$#@%&()!\'^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  let errMsgAlphaNumericSpecChar =  translate(
    Namespace.ACE_OCN_BILLOFLADING,
    AceOcnVesselMasterKey.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )

  const regexAlphaNumericSpace = AceOceanValidationRegex.REGEX_ALPHA_NUMERIC_SPACE
  let errMsgAlphaNumericSpace =  translate(
    Namespace.ACE_OCN_BILLOFLADING,
    AceOcnVesselMasterKey.Validations.ERR_MSG_ALPHA_NUMERIC_SPACE
  )

  return Yup.object({
    vesselCode: Yup.string().nullable().max(35,"Max allowed length is 35").matches(regexAlphaNumericSpecChar,errMsgAlphaNumericSpecChar),
    vesselName: Yup.string().nullable().max(35,"Max allowed length is 35").matches(regexAlphaNumericSpecChar,errMsgAlphaNumericSpecChar),
    vesselOwner: Yup.string().nullable().max(35,"Max allowed length is 35").matches(regexAlphaNumericSpecChar,errMsgAlphaNumericSpecChar),
    vesselCountry: Yup.string().nullable(),
  })
}

export default makeValidationSchema
