import Namespace from 'src/constants/locale/Namespace'
import { Yup } from 'cng-web-lib'
import {
  constants,
} from 'cng-web-lib'
import * as AceOceanValidationRegex from 'src/common/AceOceanValidationRegex'
import AceOcnManifestKeys from 'src/constants/locale/key/AceOcnManifest'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )


  const regexAlphaNumeric = AceOceanValidationRegex.REGEX_ALPHA_NUMERIC;
  let errMsgAlphaNumeric = translate(
    Namespace.ACE_OCN_MANIFEST,
    AceOcnManifestKeys.Validations.ERR_MSG_ALPHA_NUMERIC
  )

  const regexAlphaNumericSpace = AceOceanValidationRegex.REGEX_ALPHA_NUMERIC_SPACE
  let errMsgAlphaNumericSPace = translate(
    Namespace.ACE_OCN_MANIFEST,
    AceOcnManifestKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPACE
  )

  return Yup.object({
    scac: Yup.string().required(requiredMessage).nullable().max(4,"Max allowed length is 04.").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    voyageNumber: Yup.string().required(requiredMessage).nullable().max(5,"Max allowed length is 5.").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    vesselName: Yup.string().required(requiredMessage).nullable().max(23,"Max allowed length is 23.").matches(regexAlphaNumericSpace,errMsgAlphaNumericSPace),
    portOfDischarge: Yup.string().required(requiredMessage).nullable(),
    vesselCountry: Yup.string().nullable(),
    etaDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).required(requiredMessage).nullable(),
    modeOfTransport: Yup.string().required(requiredMessage).nullable(),
    paperlessInbondParticipant: Yup.string().required(requiredMessage).nullable(),
    issuerScac: Yup.string().required(requiredMessage).nullable().max(4,"Max allowed length is 04.").matches(regexAlphaNumeric,errMsgAlphaNumeric),
  })
}

export default makeValidationSchema
