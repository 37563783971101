import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import * as AceOceanValidationRegex from 'src/common/AceOceanValidationRegex'
import AceOcnBillofladingKeys from 'src/constants/locale/key/AceOcnBilloflading'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric = AceOceanValidationRegex.REGEX_ALPHA_NUMERIC
  let errMsgAlphaNumeric = translate(
    Namespace.ACE_OCN_BILLOFLADING,
    AceOcnBillofladingKeys.Validations.ERR_MSG_ALPHA_NUMERIC
  )


  return Yup.object({
    headerId: Yup.string(),
    containerNumber: Yup.string().nullable().max(14,"Max Length allowed is 14").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    typeCode: Yup.string().nullable(),
    descriptionCode: Yup.string().nullable(),
    sealNumber: Yup.string().nullable().max(15,"Max Length allowed is 15").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    serviceCode: Yup.string().nullable(),
    containerStatus: Yup.string().nullable(),
  })
}

export default makeValidationSchema
